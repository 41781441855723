<template>
  <b-row>

    <b-col
      md="3"
    >
      <b-card
        title="Ajouter un document"
      >
        <b-form @submit.prevent="form.slug?editDocument():storeDocument()">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Type de document"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :formatter="formatter"

                  placeholder="Type de document"
                />
                <span
                  v-if="errors.name"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.name[0]"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="description"
              >

                <b-form-textarea
                  id="description"
                  v-model="form.description"

                  placeholder="Description"
                  rows="3"
                />
                <span
                  v-if="errors.description"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.description[0]"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 btn-block"
                size="sm"
                :disabled="documentProcess"
              >
                {{ form.slug? 'Modifier' : 'Ajouter' }}
                <b-spinner
                  v-if="documentProcess"
                  small
                  label="Loading..."
                  variant="light"
                  class="ml-1"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col
      md="9"
    >
      <b-card
        title="Liste des documents"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="getDocument(data.item)"
              >
                <feather-icon icon="EditIcon" />
                Modifier
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'

import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useDocuments from '@/services/admin/documentService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,

  },
  directives: {
    Ripple,
  },

  setup() {
    const {
      documents, getDocuments, createDocument, errors, documentProcess, documentSuccess, loader, document, updateDocument,
    } = useDocuments()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = reactive([
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ])
    const form = reactive({
      name: '',
      description: '',
    })
    /* eslint-disable global-require */
    const items = documents

    onMounted(() => {
      getDocuments()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const info = (item, index, button) => {
      infoModal.title = `Row index: ${index}`
      infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', infoModal.id, button)
    }

    const resetInfoModal = () => {
      infoModal.title = ''
      infoModal.content = ''
    }
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const sortOptions = computed(() => {
      fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    })

    // Reset Form
    const resetForm = () => {
      form.name = null
      form.description = null
      form.slug = null
    }

    // Enregistrer une document
    const storeDocument = async () => {
      await createDocument({ ...form }).then(() => {
        if (documentSuccess.value === true) {
          resetForm()
        }
      })
    }

    // Obtenir une Document par son slug
    const getDocument = async item => {
      form.name = item.name
      form.description = item.description
      form.slug = item.slug
    }

    // Modifier une Document
    const editDocument = async () => {
      await updateDocument(form).then(() => {
        if (documentSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      info,
      resetInfoModal,
      onFiltered,
      sortOptions,
      documents,
      form,
      errors,
      storeDocument,
      // validateState,
      formatter,
      documentProcess,
      document,
      getDocument,
      editDocument,
      loader,
    }
  },

}
</script>
